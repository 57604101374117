import img089TU4Ek6SR8wy9uZsFf9y from "/img/album_art/089TU4Ek6SR8wy9uZsFf9y_Crazy_feat_Eljé.jpg";
import img0Aav2kdBJ4i6UUIg3SpFBR from "/img/album_art/0Aav2kdBJ4i6UUIg3SpFBR_Never_Gonna_Love_goddard_Edit.jpg";
import img0C3shWEOObGT5IxApC7Mkg from "/img/album_art/0C3shWEOObGT5IxApC7Mkg_you_were_there_for_me.jpg";
import img0LDDluRE805Dvt7WA0tDvA from "/img/album_art/0LDDluRE805Dvt7WA0tDvA_closure.jpg";
import img0P88fVbvRQGgRQN1GulQNy from "/img/album_art/0P88fVbvRQGgRQN1GulQNy_Glad_U_Came_feat_ZieZie.jpg";
import img0mNi3gEjt1CmZVhXFFjx8d from "/img/album_art/0mNi3gEjt1CmZVhXFFjx8d_Its_a_Beautiful_Day_Reprise.jpg";
import img1E9T8kPAQV2zMSf0hz3K6A from "/img/album_art/1E9T8kPAQV2zMSf0hz3K6A_Call_Me_Up.jpg";
import img1I4lCSP69P74nU3a6Su5L2 from "/img/album_art/1I4lCSP69P74nU3a6Su5L2_Jiggle_Jiggle.jpg";
import img1LFrlYjezwFadKoL6UESMd from "/img/album_art/1LFrlYjezwFadKoL6UESMd_wish_you_would.jpg";
import img1jrjUPWrfMw8RBbZ9PlX0W from "/img/album_art/1jrjUPWrfMw8RBbZ9PlX0W_Highs_Lows.jpg";
import img1kCqGMbhjJdsTwi10t7Rqx from "/img/album_art/1kCqGMbhjJdsTwi10t7Rqx_Change_Ur_Life.jpg";
import img1snwbIFzhiPvpodAbvNJpi from "/img/album_art/1snwbIFzhiPvpodAbvNJpi_Behind_These_Hazel_Eyes.jpg";
import img1wNlxeNoKgMK3pAuZCHDcJ from "/img/album_art/1wNlxeNoKgMK3pAuZCHDcJ_Beautiful_Day_Thank_You_for_Sunshine.jpg";
import img1xIqDFx3KdByBnKMlTECcM from "/img/album_art/1xIqDFx3KdByBnKMlTECcM_Coming_for_You_feat_A1_x_J1.jpg";
import img1z5r6Rpcc1BreRtYALBncX from "/img/album_art/1z5r6Rpcc1BreRtYALBncX_Beautiful_Day_Thank_You_for_Sunshine.jpg";
import img25gJ0A6BxeoO9MabNwosAG from "/img/album_art/25gJ0A6BxeoO9MabNwosAG_Beautiful_Day_Thank_You_for_Sunshine_ME13_Beats_Instrumental.jpg";
import img26AXhNPL6CBLlsV49j5opa from "/img/album_art/26AXhNPL6CBLlsV49j5opa_Without_You.jpg";
import img2H1Bi3y9Udj3KfuyRCh0Ie from "/img/album_art/2H1Bi3y9Udj3KfuyRCh0Ie_Beautiful_Day_Thank_You_for_Sunshine_Heartf3lt_Drill_Edit.jpg";
import img2O7TmAEKiBLQUTNBq2yH9W from "/img/album_art/2O7TmAEKiBLQUTNBq2yH9W_fight_or_flight.jpg";
import img2jBt7c8o1dDuyIIgepcNsz from "/img/album_art/2jBt7c8o1dDuyIIgepcNsz_you_were_there_for_me.jpg";
import img37ZOqyxz7B7bmIa2sq5odC from "/img/album_art/37ZOqyxz7B7bmIa2sq5odC_eighteen.jpg";
import img3O0OhpTJu2DV2yIYZAfhzf from "/img/album_art/3O0OhpTJu2DV2yIYZAfhzf_orbit.jpg";
import img3jTk3JX7wN0CO60welQgnJ from "/img/album_art/3jTk3JX7wN0CO60welQgnJ_Gone.jpg";
import img4yaalj9cPc9FCVi0SXkBuV from "/img/album_art/4yaalj9cPc9FCVi0SXkBuV_Take_Me_to_the_River_I_Will_Swim.jpg";
import img65zIby9Z03d1bhGvLNJNrA from "/img/album_art/65zIby9Z03d1bhGvLNJNrA_Soaked.jpg";
import img6EIMUjQ7Q8Zr2VtIUik4He from "/img/album_art/6EIMUjQ7Q8Zr2VtIUik4He_drunk_text.jpg";
import img6Jfn2Ut4F7snIXmOp7LLco from "/img/album_art/6Jfn2Ut4F7snIXmOp7LLco_Clearest_of_Signs.jpg";
import img6Z1IBgysq1FfSzhQWUdvAc from "/img/album_art/6Z1IBgysq1FfSzhQWUdvAc_pick_up_the_phone.jpg";
import img6c8gf4UJ2Od8uKUiCYdf5a from "/img/album_art/6c8gf4UJ2Od8uKUiCYdf5a_one_more_night.jpg";
import img6lft9WPolzGl1c0sxWAMsZ from "/img/album_art/6lft9WPolzGl1c0sxWAMsZ_Peter_Pan_feat_Liilz.jpg";
import img6n0tjWhWk846PxAAQo9kb7 from "/img/album_art/6n0tjWhWk846PxAAQo9kb7_Life_Story.jpg";
import img6scm1j3DHw8v3fQruA9q47 from "/img/album_art/6scm1j3DHw8v3fQruA9q47_Malicious_Intentions.jpg";
import img6xtW6EBqnClqaLfsxKyh3u from "/img/album_art/6xtW6EBqnClqaLfsxKyh3u_pick_up_the_phone.jpg";
import img7FBsCEfKsrj5hbcn5PZDyP from "/img/album_art/7FBsCEfKsrj5hbcn5PZDyP_beat_up_car.jpg";
import img7cbvHeOb1SjNxkbqDuilw2 from "/img/album_art/7cbvHeOb1SjNxkbqDuilw2_hiatus.jpg";
import img7diD1MucbcRUVjS6AA9ehm from "/img/album_art/7diD1MucbcRUVjS6AA9ehm_Kitchen_Stove.jpg";

export const images = [
img089TU4Ek6SR8wy9uZsFf9y,
img0Aav2kdBJ4i6UUIg3SpFBR,
img0C3shWEOObGT5IxApC7Mkg,
img0LDDluRE805Dvt7WA0tDvA,
img0P88fVbvRQGgRQN1GulQNy,
img0mNi3gEjt1CmZVhXFFjx8d,
img1E9T8kPAQV2zMSf0hz3K6A,
img1I4lCSP69P74nU3a6Su5L2,
img1LFrlYjezwFadKoL6UESMd,
img1jrjUPWrfMw8RBbZ9PlX0W,
img1kCqGMbhjJdsTwi10t7Rqx,
img1snwbIFzhiPvpodAbvNJpi,
img1wNlxeNoKgMK3pAuZCHDcJ,
img1xIqDFx3KdByBnKMlTECcM,
img1z5r6Rpcc1BreRtYALBncX,
img25gJ0A6BxeoO9MabNwosAG,
img26AXhNPL6CBLlsV49j5opa,
img2H1Bi3y9Udj3KfuyRCh0Ie,
img2O7TmAEKiBLQUTNBq2yH9W,
img2jBt7c8o1dDuyIIgepcNsz,
img37ZOqyxz7B7bmIa2sq5odC,
img3O0OhpTJu2DV2yIYZAfhzf,
img3jTk3JX7wN0CO60welQgnJ,
img4yaalj9cPc9FCVi0SXkBuV,
img65zIby9Z03d1bhGvLNJNrA,
img6EIMUjQ7Q8Zr2VtIUik4He,
img6Jfn2Ut4F7snIXmOp7LLco,
img6Z1IBgysq1FfSzhQWUdvAc,
img6c8gf4UJ2Od8uKUiCYdf5a,
img6lft9WPolzGl1c0sxWAMsZ,
img6n0tjWhWk846PxAAQo9kb7,
img6scm1j3DHw8v3fQruA9q47,
img6xtW6EBqnClqaLfsxKyh3u,
img7FBsCEfKsrj5hbcn5PZDyP,
img7cbvHeOb1SjNxkbqDuilw2,
img7diD1MucbcRUVjS6AA9ehm,
];